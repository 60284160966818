import apiTool from '@/command/apiTool'
import { getAction, postAction, delAction } from '@/command/netTool'
import DrawerForm from '@/components/DrawerForm'
import { Message } from 'ant-design-vue'
import moment from 'moment'
import RewardSetting from '../com/invite_friends/RewardSetting.vue'
import UserManagement from '../com/invite_friends/UserManagement.vue'

let formData = {}

let pageTotal = 10,
  pageCurrent = 1

function onFetchInit(data) {
  const { total } = data
  pageTotal = total

  return {
    form: data
  }
}

//活动添加编辑
function getForm(form, { update }) {
  return [
    {
      form: [
        {
          name: '活动名称',
          type: 'input',
          key: 'name',
          disabled: formData.status == '1',
          rules: [
            {
              required: true,
              message: '请输入活动名称'
            }
          ]
        },
        {
          name: '活动时间',
          type: 'rangePicker',
          keys: ['startTime', 'endTime'],
          disabled: formData.status == '1',
          showTime: true,
          rules: [
            {
              required: true,
              message: '请选择活动时间'
            }
          ]
        },
        {
          name: '邀请条件',
          type: 'radioButton',
          disabled: formData.status == '1',
          key: 'type',
          typeData: [
            {
              name: '邀请注册',
              value: '1'
            },
            {
              name: '邀请购买',
              value: '2'
            }
          ],
          rules: [
            {
              required: true,
              message: '请选择邀请条件'
            }
          ]
        },
        // {
        //   label: '最低购买金额',
        //   type: 'inputNumber',
        //   key: 'goodsId',
        //   styles: {
        //     marginLeft: '14%',
        //     width: '60%'
        //   },
        //   desc: '注：邀请用户最低购买金额，0表示不限制金额'
        // },
        {
          name: '活动规则',
          type: 'textArea',
          key: 'rules',
          rules: [
            {
              required: true,
              message: '请输入活动规则'
            }
          ]
        }
      ]
    }
  ]
}

//活动添加/编辑
function onOpen({ getDetail }, title) {
  apiTool.showDrawer({
    title: title,
    view: DrawerForm,
    width: '700px',
    viewProps: {
      formProps: {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 }
      },
      form: formData,
      data: getForm
    },
    success({ data, setHidden }) {
      if (JSON.stringify(data) === '{}') {
        Message.warning('请完成表单填写')
        return
      }

      postAction('/activities/add_or_modify', data).then(res => {
        Message.success('保存成功')
        setHidden()
        getDetail()
      })
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close,
          display: true
        },
        {
          name: '保存',
          type: 'primary',
          onClick: submit,
          display: title != '商品详情'
        }
      ].filter(e => e.display)
    }
  })
}

function rewardSetting({ getDetail }, title, { id, status }) {
  apiTool.showDrawer({
    title: title,
    view: RewardSetting,
    width: '780px',
    viewProps: {
      acData: { activityId: id, activityStatus: status }
    },
    success({ data, setHidden }) {
      console.log(data)
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        }
        // {
        //   name: '保存',
        //   type: 'primary',
        //   onClick: submit
        // }
      ]
    }
  })
}

function userManagement({ getDetail }, title, params) {
  apiTool.showDrawer({
    title: title,
    view: UserManagement,
    width: '780px',
    viewProps: {
      acData: params
    },
    foot: ({ close, submit }) => {
      return [
        {
          name: '取消',
          onClick: close
        }
      ]
    }
  })
}

export default function({ getDetail }) {
  return {
    type: 'cardFormTable',
    url: '/activities/page',
    params: {
      size: 10,
      current: pageCurrent
    },
    onFetchInit,
    data: bgData => {
      return [
        {
          form: [
            {
              label: '新建活动',
              type: 'button',
              cols: 3,
              props: {
                type: 'primary',
                ghost: true,
                style: {
                  width: '100px'
                }
              },
              onClick: () => {
                formData = {}
                onOpen({ getDetail }, '新建活动')
              }
            },
            {
              type: 'table',
              labelCol: { span: 0 },
              wrapperCol: { span: 24 },
              dataSource: bgData['records'],
              showFootButton: false,
              showRowSelect: false,
              showPagination: true,
              pagination: {
                total: pageTotal,
                hideOnSinglePage: true,
                showSizeChanger: false,
                onChange: (page, pageSize) => {
                  pageCurrent = page
                  getDetail()
                }
              },
              columns: [
                {
                  dataIndex: 'name',
                  title: '活动名称',
                  align: 'left',
                  customRender: function(text, records, index, h) {
                    return (
                      <div class="product-order-item-copy">
                        <div>{text}</div>
                      </div>
                    )
                  }
                },
                {
                  dataIndex: 'startTime',
                  title: '活动时间',
                  align: 'left',
                  customRender: function(text, records, index, h) {
                    return (
                      moment(text).format('YYYY-MM-DD HH:mm') +
                      ' - ' +
                      moment(records.endTime).format('YYYY-MM-DD HH:mm')
                    )
                  }
                },
                {
                  dataIndex: 'status',
                  title: '状态',
                  type: 'badge',
                  render(data) {
                    return {
                      showDot: true,
                      name: ['未开始', '活动中', '已结束'][data],
                      color: ['#f59a23', '#18be6b', '#ed4014'][data]
                    }
                  }
                },
                {
                  title: '操作',
                  customRender: function customRender(text, records, index, h) {
                    return (
                      <div>
                        <a
                          v-show={records.status != '2'}
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            if (records.publishStatus == '1') {
                              getAction(`/activities/take_down/${records.id}`).then(res => {
                                Message.success('下架成功!')
                                getDetail()
                              })
                            } else {
                              getAction(`/activities/take_up/${records.id}`).then(res => {
                                Message.success('上架成功!')
                                getDetail()
                              })
                            }
                          }}
                        >
                          {records.publishStatus == '1' ? '下架' : '上架'}
                        </a>
                        <a
                          v-show={records.status != '2'}
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            getAction(`/activities/detail/${records.id}`).then(res => {
                              formData = res.data
                              onOpen({ getDetail }, '编辑商品')
                            })
                          }}
                        >
                          编辑
                        </a>
                        <a
                          v-show={records.status == '2'}
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            getAction(`/activities/detail/${records.id}`).then(res => {
                              formData = res.data
                              onOpen({ getDetail }, '商品详情')
                            })
                          }}
                        >
                          详情
                        </a>
                        <a
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            userManagement({ getDetail }, '用户管理', records)
                          }}
                        >
                          用户管理
                        </a>
                        <a
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            rewardSetting({ getDetail }, '奖励设置', records)
                          }}
                        >
                          奖励设置
                        </a>
                        <a
                          v-show={records.status == '2'}
                          onClick={() => {
                            delAction(`/activities/delete?ids=${records.id}`).then(res => {
                              Message.success('删除成功!')
                              getDetail()
                            })
                          }}
                        >
                          删除
                        </a>
                      </div>
                    )
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
